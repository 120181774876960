import { useState, useEffect } from 'react'
import axios from "axios";

import swal from 'sweetalert';
import "./static/guiasEnvia.css"
import { ordenarElementos } from '../../../global/functions';

const GuiasEnvia = ({ apiUrlState }) => {

  const [guiasPendientes, setGuiasPendientes] = useState([]);

  /** Obtiene el listado de pedidos que no tienen guía generada */
  const obtenerGuias = async () => {
    const url = `${apiUrlState}/Api/Envia/getGuiasPendientes`;
    const response = await axios.get(url);
    if (!response.data.error) {
      const guias = ordenarElementos(response.data.data, "NroPedido");
      setGuiasPendientes(guias);
    }
  }

  const handleButtonGenerar = (pedido) => {
    const cliente = pedido.Cliente;
    swal({
      title: "Importante",
      text: `Esta acción creará la guía del pedido N° ${pedido.NroPedido} para\
       ${cliente.Nombres} ${cliente.Apellidos} desea continuar?.`,
      icon: "warning",
      buttons: {
        confirm: {
          text: "Continuar",
          value: true,
          visible: true,
          closeModal: true
        },
        cancel: {
          text: "Cancelar",
          value: null,
          visible: true,
          closeModal: true,
        }
      }
    }).then(async (resp) => {
      if (resp) {
        await generarGuiaEnvia(pedido.NroPedido);
      }
    })
  }

  /** Genera la guia de un pedido con los datos del pedido almacenados */
  const generarGuiaEnvia = async (numPedido) => {
    const url = `${apiUrlState}/Api/Envia/GenerarGuia`;
    const data = {
      x_cod_respuesta: 1,
      x_extra1: numPedido,
      reintentos: false,
    }
    const response = await axios.post(url, data);
    if (!response.data.error) {
      const guias = response.data.guias;
      swal(
        "Listo!",
        `Guía generada exitosamente para el pedido N° ${numPedido}.\
         Numeros guía: ${guias}.`,
        "success"
      );
    } else {
      swal("Oops!", "No fue posible generar la guía, intenta mas tarde", "error");
    }
  }  

  const handleAnularPedido = (pedido) => {
    const cliente = pedido.Cliente;
    swal({
      title: "Importante",
      text: `Esta acción anulará el pedido N° ${pedido.NroPedido} de\
       ${cliente.Nombres} ${cliente.Apellidos} desea continuar?.`,
      icon: "warning",
      buttons: {
        confirm: {
          text: "Continuar",
          value: true,
          visible: true,
          closeModal: true
        },
        cancel: {
          text: "Cancelar",
          value: null,
          visible: true,
          closeModal: true,
        }
      }
    }).then(async (resp) => {
      if (resp) {
        await anularPedido(pedido.NroPedido);
      }
    })
  }

  /** Establece la propiedad un pedido Anulado:true
   *  para que no se muestre en la lista */
  const anularPedido = async (numPedido) => {
    const url = `${apiUrlState}/Api/Envia/AnularPedido`;
    const response = await axios.put(url, { numPedido });
    if (!response.data.error) {
      swal(
        "Listo!",
        `El pedido N° ${numPedido} fue anulado con éxito.`,
        "success"
      );
      const listaGuiasActualizada = guiasPendientes.filter(guia => guia.NroPedido !== numPedido);
      setGuiasPendientes(listaGuiasActualizada);
    }
  }

  useEffect(() => {
    obtenerGuias();    
  }, [])
  
  
  return (
    <div style={{ margin: "5vh 5vw" }}>
      <h1>Pedidos Pendientes Envía</h1>
      <table className='table-send' >
        <thead >
          <tr>
            <th>Pedido N°</th>
            <th>Cliente</th>
            <th>Acción</th>
          </tr>
        </thead>
        <tbody>
        {
          guiasPendientes.map((pedido) => (
            <tr key={pedido.NroPedido}>
              <th>{pedido.NroPedido}</th>
              <td>{pedido.Cliente.Nombres} {pedido.Cliente.Apellidos}</td>
              <td>
                <button 
                  className='btn btn-dark btn-sm'
                  onClick={() => handleButtonGenerar(pedido)}
                >Generar guía</button>

                <button
                  className='btn btn-warning btn-sm'
                  onClick={() => handleAnularPedido(pedido)}
                >Anular pedido</button>
              </td>
            </tr>
          ))
        }          
        </tbody>
      </table>
    </div>
  )
}

export default GuiasEnvia;