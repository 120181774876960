import React from 'react';
import Carousel from 'react-bootstrap/Carousel'
import { useMediaQuery } from 'react-responsive';

const CarouselInfo = ({
  index,
  images,
}) => {

  const styleImg = {
    width: "100%",
    height: "auto",
    objectFit: "cover"
  }

  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <Carousel interval={5000} controls={false} wrap={true} slide={!isMobile} style={index? {marginTop:"80px"} : {} }
    >
      <Carousel.Item style={{height:"auto"}}>
        <picture>
          <source srcSet={images.img1[0]} media="(min-width: 1400px)" />
          {/* <source srcSet={images.img1[1]} media="(min-width: 769px)" />
          <source srcSet={images.img1[2]} media="(min-width: 577px)" /> */}
          <img
            srcSet={images.img1[0]}
            alt="responsive image"
            style={styleImg}
          />
          {/* 600x500 */}
        </picture>
      </Carousel.Item>
      <Carousel.Item style={{height:"auto"}}>
        <picture>
          <source srcSet={images.img2[0]} media="(min-width: 1400px)" />
          {/* <source srcSet={images.img2[1]} media="(min-width: 769px)" />
          <source srcSet={images.img2[2]} media="(min-width: 577px)" /> */}
          <img
            srcSet={images.img2[0]}
            alt="responsive image"
            style={styleImg}
          />
          {/* 600x500 */}
        </picture>
      </Carousel.Item>
      <Carousel.Item style={{height:"auto"}}>
        <picture>
          <source srcSet={images.img3[0]} media="(min-width: 1400px)" />
          {/* <source srcSet={images.img3[1]} media="(min-width: 769px)" />
          <source srcSet={images.img3[2]} media="(min-width: 577px)" /> */}
          <img
            srcSet={images.img3[0]}
            alt="responsive image"
            style={styleImg}
          />
        </picture>
      </Carousel.Item>
      <Carousel.Item style={{height:"auto"}}>
        <picture>
          <source srcSet={images.img4[0]} media="(min-width: 1400px)" />
          {/* <source srcSet={images.img4[1]} media="(min-width: 769px)" />
          <source srcSet={images.img4[2]} media="(min-width: 577px)" /> */}
          <img
            srcSet={images.img4[0]}
            alt="responsive image"
            style={styleImg}
          />
        </picture>
      </Carousel.Item>
    </Carousel>
  );
}

export default CarouselInfo;