/** Funciones de uso global */

export const actualizarEventId = () => {
  let facebook_event_id = localStorage.getItem("facebook_event_id");
  if(!facebook_event_id) {
    const id = Date.now();
    localStorage.setItem("facebook_event_id", JSON.stringify(id));
    facebook_event_id = id;
  } else {
    const newEvent_id = Date.now();
    const diferencia = newEvent_id - parseInt(facebook_event_id)
    const miliseg_hora = 3600000;
    if(diferencia > miliseg_hora) {
      localStorage.setItem("facebook_event_id", JSON.stringify(newEvent_id));
      facebook_event_id = localStorage.getItem("facebook_event_id");
    }
  }
  return facebook_event_id;
}

/** Ordena una lista de elementos de acuerdo al atributo ingresado */
export const ordenarElementos = (lista, attrib) => {
  const listaOrdenada = lista.sort((a,b) => a[attrib] - b[attrib]);
  return listaOrdenada
}