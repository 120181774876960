import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios';
import swal from "sweetalert";
import "../static/css/Producto/modalCompra.css"

/** Importacion de servicios */
import apiFacebook from "../../../services/apiFacebook";

import "animate.css"

const CompraRapida = ({
  apiUrlState,
  headerState,
  productoCompra,
  setProductoCompra,
  activado,
  setActivado,
  carrito,
  modificarCarritoState,
  actualizarSessionStorage,
  spinnerState,
  modificarSpinnerState,
  errorState,
  modificarErrorState,
}) => {

  const recursosUrlState = "https://hosting.basic.dekada.co/BasicCouture";
  const [imgVarianteUrl, setImgVarianteUrl] = useState(""); // Url de la imagen de la variante
  const [selectVariante, setSelectVariante] = useState(null); // Variante seleccionada
  const [tallas, setTallas] = useState([]); // Contiene la lista de tallas de una variante
  const [selectTalla, setSelectTalla] = useState(null); // Talla seleccionada en modal
  const [cantidad, setCantidad] = useState(1); // Cantidad segun talla en el input
  const updateTalla = useRef(true);
  const ruta = useRef(window.location.pathname);

  /** Envia el evento de agregar al carrito pagina web a facebook */
  const enviarEventoFacebook = async (producto) => {
    const userIP = await apiFacebook.get();
    const item_price =  headerState["Cliente"] == "Mayoristas"
      ? producto.PrecioMayorista
      : producto.PrecioMinorista;
    apiFacebook.post(`${apiUrlState}/Api/Facebook/SendEvent`, {
      event_id: `cart-${Date.now()}`,
      event_name: "AddToCart",
      event_source_url: window.location.href,
      client_user_agent: navigator.userAgent,
      client_ip_address: userIP || "0.0.0.0",
      custom_data: {
        currency: "COP",
        contents: [
          {
            id: producto.id,
            item_price,
          }
        ]
      }
    });

    /** Activar evento mediante pixel -> Pendiente */
    
  }

  const cambiarState = () => {
    setActivado(false);
    setCantidad(1);
    setProductoCompra(null);
  }

  const handleVariante = (variante) => {
    setImgVarianteUrl(`${recursosUrlState}/img/Productos/Ref ${variante.Referencia}.webp`);
    setSelectVariante(variante);
    setCantidad(1)
  };

  const changeSelectedTalla = (value, talla) => {
    updateTalla.current = false;
    let temp = tallas;
    setCantidad(1);
    temp = temp.map((element) => {
      return { ...element, estado: value === element.value ? true : false };
    });
    // setCantidad(talla.Cantidad);
    setSelectTalla(talla);
    setTallas(temp);
  };

  const handleCantidad = (accion) => {
    if(accion === "add" && cantidad < selectTalla.Cantidad) {
      setCantidad(cantidad + 1);
    } else if(accion === "sub" && cantidad > 1) {
      setCantidad(cantidad - 1);
    }
  }

  const handleChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,3}$/.test(value)) { // Limitar a 3 dígitos
      setCantidad(value);
    }
  };

  const ObtenerTallas = () => {
    let tallas = [];
    for (let talla of selectVariante.Variantes[0].Tallas) {
      if (talla.Cantidad > 0) {
        tallas.push({
          value: talla._id,
          label: talla.Talla,
          image:
          recursosUrlState + "/img/Filtros/" + talla.Talla + "-blanco.webp",
          Cantidad: talla.Cantidad,
          estado: false,
        });
      }
    }
    setTallas(tallas);
  };

  //Retorna el precio ingresado sin signos de peso ni puntos.
  const arreglarPrecio = (precio) => {
    var separacion = precio;
    if (precio.indexOf("$") !== -1) separacion = precio.split(" ")[1];
    var separacion2 = separacion.split(".");
    return parseInt(separacion2[0] + separacion2[1]);
  };

  /** Agrega las cantidades del input al carrito validando existencias */
  const adicionarV = async (talla, cantidadActual, cantidadAdicion) => {
    let undAdicion = cantidadAdicion;
    if (selectTalla.length !== 0) {
      let cantidadReal = await ConsultarBodega(selectVariante.Referencia + talla, selectVariante.CodBodega);
      if (cantidadActual + parseInt(undAdicion) <= cantidadReal) {
        auxAdicionarV(talla, undAdicion)
        setCantidad(1);
      } else {
        undAdicion = cantidadReal - cantidadActual;
        if(undAdicion > 0) {
          swal({
            title: "Aviso",
            text: `La cantidad solicitada de la referencia ${selectVariante.Referencia + talla} no está disponible.
              Se agregarán ${undAdicion} de ${cantidadAdicion} al carrito, desea continuar?`,
            icon: "info",
            buttons: {
              cancel: {
                text: "No",
                value: null,
                visible: true,
                closeModal: true,
              },
              confirm: {
                text: "Si",
                value: true,
                visible: true,
                closeModal: true
              }
            }
          }).then(response => {
            if(response) {
              auxAdicionarV(talla, undAdicion)
            }
            setCantidad(1);
          });
        } else {
          if(obtenerCantidadTallaProductoActual(selectTalla.label) == 0) {
            swal({
              title: "¡Importante!",
              text: `La referencia ${selectVariante.Referencia + talla} no será agregada al carrito,
              es posible que esté agotada. Si se presenta la novedad con todas las referencias,
              solicite soporte.`,
              icon: "warning",
            });
          }
        }
      }
    }
  };

  const auxAdicionarV = (talla, cantidadAdicion) => {
    let crear = true;
    for (var i = 0; i < carrito["Carrito"].length; i++) {
      if (selectVariante.Referencia + talla === carrito["Carrito"][i]["id"]) {
        carrito["Carrito"][i]["Cantidad"] += parseInt(cantidadAdicion);
        crear = false;
      }
    }
    if (crear) {
      const productoNuevo = {
        _id: selectVariante._id,
        id: selectVariante.Referencia + talla,
        Nombre: selectVariante.Nombre,
        Codigo: selectVariante.Referencia,
        Talla: talla,
        Bodega: selectVariante.CodBodega,
        Categoria: selectVariante.Categoria.Categoria,
        PrecioMinorista: arreglarPrecio(selectVariante.PrecioMinorista),
        PrecioMayorista: arreglarPrecio(selectVariante.PrecioMayorista),
        DescuentoMayorista: selectVariante.DescuentoMayorista,
        DescuentoMinorista: selectVariante.DescuentoMinorista,
        Cantidad: parseInt(cantidadAdicion),
      };
      const carritoNew = [...carrito["Carrito"], productoNuevo];
      modificarCarritoState({ Accion: "Activo", Carrito: carritoNew });
      actualizarSessionStorage("Carrito", JSON.stringify(carritoNew));
      enviarEventoFacebook(productoNuevo)
    } else {
      const carritoNew = [...carrito["Carrito"]];
      modificarCarritoState({ Accion: "Activo", Carrito: carritoNew });
      actualizarSessionStorage("Carrito", JSON.stringify(carritoNew));
    }
  }

  const obtenerCantidadTallaProductoActual = (talla) => {
    const productoFiltrado = carrito["Carrito"].filter(
      (prod) => prod["id"] === selectVariante.Referencia + talla
    );
    return productoFiltrado.length > 0 ? productoFiltrado[0]["Cantidad"] : 0;
  };

  //Se realiza la peticion para la verificacion de un producto solicitando la cantidad de este en bodega.
  const ConsultarBodega = async (idProducto, codBodega) => {
    let res = -100;
    if (!spinnerState["Desplegar"]) {
      modificarSpinnerState({
        Desplegar: !spinnerState["Desplegar"],
        Texto: "Agregando producto...",
      });
    }
    let rutaVerificacion = "/Api/Manager/VerificarProducto/" + idProducto
    if (localStorage.getItem("Vendedor")) {
      rutaVerificacion = "/Api/Manager/VerificarProducto/" + idProducto + `/${codBodega}`
    }

    await axios
      .get(
        apiUrlState + rutaVerificacion,
        {},
        {}
      )
      .then((response) => {
        if (response.data.existe) {
          modificarSpinnerState({
            Desplegar: false,
            Texto: spinnerState["texto"],
          });
          if (errorState["Error"])
            modificarErrorState({
              Error: false,
              Mensaje: "Todo bien, todo correcto",
            });
          res = response.data.cantidad;
          return response.data.cantidad;
        } else {
          modificarSpinnerState({
            Desplegar: false,
            Texto: spinnerState["texto"],
          });
          if (!errorState["Error"])
            modificarErrorState({
              Error: true,
              Mensaje: "Talla no disponible para esta referencia.",
            });
          return -100;
        }
      })
      .catch(function (err) {
        if (!errorState["Error"]) {
          modificarErrorState({
            Error: true,
            Mensaje: err.response.data.error_message,
          });
          modificarSpinnerState({
            Desplegar: false,
            Texto: spinnerState["texto"],
          });
        }
      });
    return res;
  };

  const calcularAumento = (precio) => {
    const valor = parseInt(precio);
    const aumento = valor * (1.2);
    return parseInt(aumento);
  }

  useEffect(() => {
    if(productoCompra !== null) {
      setImgVarianteUrl(`${recursosUrlState}/img/Productos/Ref ${productoCompra[0].Referencia}.webp`);
      setSelectVariante(productoCompra[0]);
      setActivado(true);
    }    
  }, [productoCompra])
  
  useEffect(() => {
    if(selectVariante !== null) {
      ObtenerTallas();
    }
  }, [selectVariante])

  useEffect(() => {
    if(updateTalla.current) {
      setSelectTalla(tallas[0]);
    }
  }, [tallas])
  
  

  return (
    <>
      {activado ? (
        <div className='modal-compra animated animate__backInDown'>
          <div className='contenedor'>
            <div className='close'>
              <button className='btn-close'
                onClick={cambiarState}
              ><strong>X</strong>
              </button>
            </div>
            <div className='info'>
              <img
              className='img-muestra'
                src={imgVarianteUrl}
                alt={productoCompra[0].RefBase}
              />
              <div>
                <h5>Ref {productoCompra[0].RefBase}</h5>
                {ruta.current==="/Mayoristas"? (
                  <>
                    {productoCompra[0].DescuentoMayorista !== 0? (
                      <>
                        <h6>$ {productoCompra[0].PrecioMayorista}</h6>
                        <h5>$ {productoCompra[0].PrecioMayorista * (1 - productoCompra[0].DescuentoMayorista / 100)}</h5>
                      </>                      
                    ) : (
                      <div style={{display: "flex", gap: "5px"}}>
                        <h5>$ {productoCompra[0].PrecioMayorista}</h5>
                        <p className='prev-m'>$ {calcularAumento(productoCompra[0].PrecioMayorista)}</p>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {productoCompra[0].DescuentoMinorista !== 0? (
                      <>
                        <h6>$ {productoCompra[0].PrecioMinorista}</h6>
                        <h5>$ {productoCompra[0].PrecioMinorista * (1 - productoCompra[0].DescuentoMinorista / 100)}</h5>
                      </>                      
                    ) : (
                      <div style={{display: "flex", gap: "5px"}}>
                        <h5>$ {productoCompra[0].PrecioMinorista}</h5>
                        <p className='prev-m'>$ {calcularAumento(productoCompra[0].PrecioMinorista)}</p>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="variantes-m">
              <h5 className='title-m'>Colores</h5>
              {productoCompra.map((variante) => (
                <img
                  key={variante.Referencia}
                  alt={productoCompra[0].Referencia}
                  src={`${recursosUrlState}/img/Productos/Ref ` +
                    variante.Referencia + ".webp"}
                  onClick={()=> handleVariante(variante)}
                  onError={(e) => e.target.src = `${recursosUrlState}/img/General/base2.png`}
                />
              ))}
            </div>
            <div className="tallas">
              <h5 className='title-m'>Tallas</h5>
              <div>
                {tallas.map((talla, index) => (
                  <img
                    className=""
                    key={ talla.label + index }
                    alt={talla.label}
                    src={
                      talla.estado
                        ? recursosUrlState + "/img/Filtros/" + talla.label + "-negro.webp"
                        : recursosUrlState +
                          "/img/Filtros/" +
                          talla.label +
                          "-blanco.webp"
                    }
                    id={talla.label}
                    onClick={() => changeSelectedTalla(talla.value, talla)}
                    
                  />
                ))}
              </div>

              <div className="unidades">
                <button onClick={() => {handleCantidad("sub")}}> - </button>
                <input type="number" className='und-input'
                  value={cantidad}
                  onChange={(e) => handleChange(e) }
                />                
                <button onClick={() => {handleCantidad("add")}}> + </button>
              </div>

              <div>
                <button
                  className='addCar'
                  onClick={
                    () => adicionarV(
                      selectTalla.label,
                      obtenerCantidadTallaProductoActual(selectTalla.label),
                      cantidad
                    )}
                >
                  Agregar al carrito
                </button>
              </div>

            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default CompraRapida;