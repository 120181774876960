import React from 'react'
import "./static/HandlePage.css"

const HandlePage = ({
  headerState,
}) => {

  const handleIndex = (pagina) => {
    if (pagina === "Mayoristas" && headerState["Cliente"] !== "Mayoristas") {
      window.location.href = "/Mayoristas"
    } else if (pagina === "Minoristas" && headerState["Cliente"] !== "Minoristas") {
      window.location.href = "/"
    }
  }

  return (
    <div  className="content">
        <div>
          <button 
            className={ `button_slide ${headerState["Cliente"]==="Minoristas"? "slide_right" : ""} ` }
            onClick={() => handleIndex("Minoristas")}>Minoristas</button>
        </div>
        <div>
          <button
            className={ `button_slide ${headerState["Cliente"]==="Mayoristas"? "slide_right" : ""} ` }
            onClick={() => handleIndex("Mayoristas")}>Mayoristas</button>
        </div>
      </div>
  )
}

export default HandlePage