//Zona de importacion de componentes externos.
import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom'
import axios from "axios";
import ReactPixel from "react-facebook-pixel";
//Zona de importacion de componentes internos.
import Filtros from "../Mercado/Filtros";

/** Importacion de servicios */
import apiFacebook from "../../services/apiFacebook";
import { actualizarEventId } from "../../global/functions";

import "./static/css/NewHeader.css";
import { useLocation } from "react-router-dom";
import { useGlobalContext } from "../../providers/GlobalStatesProviders";

function Header({
  ViewDetalle,
  carrito,
  modificarCarritoState,
  headerState,
  modificarHeaderState,
  productosState,
  modificarProductosState,
  filtrosState,
  modificarFiltrosState,
  tallasState,
  modificarTallasState,
  coloresState,
  modificarColoresState,
  marcasState,
  modificarMarcasState,
  medidasState,
  modificarMedidasState,
  errorState,
  modificarErrorState,
  spinnerState,
  modificarSpinnerState,
  apiUrlState,
  modificarApiUrlState,
  recursosUrlState,
  modificarRecursosUrlState,
  // clienteState,
  // modificarClienteState,
  categoriasState,
  modificarCategoriasState,
  administradorState,
  modificarAdministradorState,
  actualizarSessionStorage,
  landingState,
  LandingHeader,
  subCategoriasState,
  modificarSubCategoriasState,
  AdminHeaderState,
  refNoCatalogoState,
  modificarRefNoCatalogoState,
  vendedor,
  modificarVendedorState,
  Bodega,
  setBodega,
  BodegaCatalogos,
  setBodegaCatalogos,
  checkedProductos,
  setCheckedProductos,
}) {
  const history = useHistory();
  const { clienteState, modificarClienteState } = useGlobalContext();
  const [consultaLupa, setConsultaLupa] = useState({ value: "block" });
  //const [AdminState, setAdminStare] = useState("SecondHeaderadm");

  const [RutaActual, setRutaActual] = useState(window.location.pathname);

  const { srcLogo, srcBolsa, displayBuscar, displayMenu, color } = headerState;
  let presionEnter = false;
  const propiedadesFiltro = {
    position: "absolute",
    top: "0%",
    left: filtrosState["posicionFiltros"],
    cursor: "pointer",
    width: filtrosState["width"],
    display: "flex",
    flexDirection: "colum",
    flexWrap: "wrap",
    alignContent: "flex-start",
    justifyContent: "flex-start",
  };

  let colorActual; //Color de la pagina (Blanco - Negro)
  let menuActual; //Imagen del menu actual (Blanco - Negro)
  let inicioSesionActual; //Imagen de inicio de sesion (Blanco - Negro)
  if (color === "blanco") {
    colorActual = "rgb(255, 255, 255)";
    menuActual = "/img/Header/iconos/menuBlanco.webp";
    inicioSesionActual = "/img/Header/botones/inicioSesion-blanco.webp";
  } else {
    colorActual = "rgb(0, 0, 0)";
    menuActual = "/img/Header/iconos/menuNegro.webp";
    inicioSesionActual = "/img/Header/botones/inicioSesion-negro.webp";
  }

  //Retorna el total de productos añadidos en cantidades.
  const contarProductos = () => {
    let cantidad = 0;
    for (var i = 0; i < carrito["Carrito"].length; i++) {
      cantidad += carrito["Carrito"][i]["Cantidad"];
    }
    return cantidad;
  };

  const getDataCart = () => {
    let value = 0, num_items = 0;
    let content_ids = [], contents = []
    for(const item of carrito["Carrito"]) {
      const id = item.Codigo;
      const quantity = item.Cantidad;
      const item_price = headerState["Cliente"] == "Mayoristas"
        ? item.PrecioMayorista
        : item.PrecioMinorista;
      value += item_price;
      num_items += quantity;
      content_ids.push(id);
      contents.push({id, quantity, item_price});
    }
    const custom_data = {
      currency: "COP",
      value,
      content_ids,
      contents,
      num_items
    }
    return custom_data;
  }
  /** Envia el evento de visita a la pagina web a facebook */
  const enviarEventoFacebook = async () => {
    const event_id = actualizarEventId();
    const userIP = await apiFacebook.get();
    apiFacebook.post(`${apiUrlState}/Api/Facebook/SendEvent`, {
      event_id: `ch-${event_id}`,
      event_name: "InitiateCheckout",
      event_source_url: window.location.href,
      client_user_agent: navigator.userAgent,
      client_ip_address: userIP || "0.0.0.0",
      custom_data: getDataCart()
    });

    /** Activar evento mediante pixel -> Pendiente */
    
  }

  //Se realiza la peticion para la verificacion de un producto solicitando el descuento correcto
  const ActulizarDescuento = async () => {
    modificarSpinnerState({
      Desplegar: !spinnerState["Desplegar"],
      Texto: "Cargando Tu Pedido",
    });
    for (let i = 0; i < carrito["Carrito"].length; i++) {
      await axios
        .get(
          apiUrlState +
            "/Api/Inventario/BusquedaPalabraRef2/" +
            carrito["Carrito"][i].Codigo +`/${Bodega}`,
          {}
        )
        .then((response) => {
          if (response.data[0].existe) {
            if (headerState["Cliente"] === "Minoristas") {
              carrito["Carrito"][i].DescuentoMinorista =
                response.data[0].data[0].DescuentoMinorista;
              const carritoNew = [...carrito["Carrito"]];
              modificarCarritoState({ Accion: "Activo", Carrito: carritoNew });
              actualizarSessionStorage("Carrito", JSON.stringify(carritoNew));
              actualizarSessionStorage("TipoCliente", "Minoristas");
            } else {
              carrito["Carrito"][i].DescuentoMayorista =
                response.data[0].data[0].DescuentoMayorista;
              const carritoNew = [...carrito["Carrito"]];
              modificarCarritoState({ Accion: "Activo", Carrito: carritoNew });
              actualizarSessionStorage("Carrito", JSON.stringify(carritoNew));
              actualizarSessionStorage("TipoCliente", "Mayoristas");
            }
          }
        });
    }

    modificarSpinnerState({
      Desplegar: false,
      Texto: spinnerState["texto"],
    });
  };

  /** Actualiza el carrito con los descuentos actuales */
  const ActulizarDescuentoV2 = async () => {
    modificarSpinnerState({
      Desplegar: !spinnerState["Desplegar"],
      Texto: "Cargando Tu Pedido",
    });
    await axios.post(apiUrlState + "/Api/Inventario/VerificarDescuentos", {Carrito: carrito["Carrito"]})
    .then((response)=>{
      if(!response.data.error) {
        for (let i = 0; i < carrito["Carrito"].length; i++) {
          const findProd = response.data.data.find(
            prod => 
              prod.Referencia === carrito["Carrito"][i].Codigo
              && prod.CodBodega === carrito["Carrito"][i].Bodega
          );
          carrito["Carrito"][i].DescuentoMinorista = findProd.DescuentoMinorista;
          carrito["Carrito"][i].DescuentoMayorista = findProd.DescuentoMayorista;
        }
        if (headerState["Cliente"] === "Minoristas") {

          const carritoNew = [...carrito["Carrito"]];
          modificarCarritoState({ Accion: "Activo", Carrito: carritoNew });
          actualizarSessionStorage("Carrito", JSON.stringify(carritoNew));
          actualizarSessionStorage("TipoCliente", "Minoristas");
        } else {

          const carritoNew = [...carrito["Carrito"]];
          modificarCarritoState({ Accion: "Activo", Carrito: carritoNew });
          actualizarSessionStorage("Carrito", JSON.stringify(carritoNew));
          actualizarSessionStorage("TipoCliente", "Mayoristas");
        }

      }
    });
    modificarSpinnerState({
      Desplegar: false,
      Texto: spinnerState["texto"],
    });
  };

  //Retorna el total del carrito a pagar segun los productos añadidos.
  const calcularTotalCarrito = () => {
    let total = 0;
    for (var i = 0; i < carrito["Carrito"].length; i++) {
      total +=
        carrito["Carrito"][i]["Cantidad"] *
        (headerState["Cliente"] === "Minoristas"
          ? carrito["Carrito"][i]["DescuentoMinorista"] !== 0
            ? calcularDescuento(
                carrito["Carrito"][i]["PrecioMinorista"],
                carrito["Carrito"][i]["DescuentoMinorista"]
              )
            : carrito["Carrito"][i]["PrecioMinorista"]
          : carrito["Carrito"][i]["DescuentoMayorista"] !== 0
          ? calcularDescuento(
              carrito["Carrito"][i]["PrecioMayorista"],
              carrito["Carrito"][i]["DescuentoMayorista"]
            )
          : carrito["Carrito"][i]["PrecioMayorista"]);
    }
    return parseInt(total);
  };

  //Añade el punto de miles al precio ingresado y lo retorna.
  const formatNumber = (amount) => {
    var temp = amount + "";
    var i = temp.length - 3;
    while (i > 0) {
      temp = temp.substring(0, i) + "." + temp.substring(i);
      i -= 3;
    }
    return " " + temp;
  };

  //Variable que contiene la cantidad total de productos en el carrito.
  const cantidadTotal = contarProductos();

  // //Obtenemos el token JWT de la api rest de Croatta.
  // const getJWT = async (callback) => {
  //   await axios
  //     .post(apiUrlState + "/auth/get-token/", {
  //       username: "CroattaWeb",
  //       password: "Since2013",
  //     })
  //     .then((response) => {
  //       if (errorState["Error"]) {
  //         modificarErrorState({
  //           Error: false,
  //           Mensaje: "Todo bien, todo correcto",
  //         });
  //       }
  //       callback(response.data);
  //     })
  //     .catch(function (err) {
  //       if (!errorState["Error"]) {
  //         modificarErrorState({
  //           Error: true,
  //           Mensaje: "Error al recuperar datos del servidor",
  //         });
  //       }
  //     });
  // };

  //Obtiene una lista de productos, ya sea de lo New o filtrado por palabra de busqueda.
  const obtenerProductosFiltro = async (palabra) => {
    modificarProductosState([
      { CambioSeccion: false, Busqueda: false, irInicio: true },
      [],
    ]);
    if (!spinnerState["Desplegar"]) {
      modificarSpinnerState({
        Desplegar: !spinnerState["Desplegar"],
        Texto: "Obteniendo tu busqueda...",
      });
    }
    if (palabra !== "") {
      await axios
        .get(apiUrlState + "/Api/Inventario/BusquedaPalabraRef/" + palabra +`/${Bodega}`, {})
        .then((response) => {
          let matrizActual = [];
          for (var i = 0; i < response.data[0].data.length; i += 1) {
            matrizActual.push(response.data[0].data[i]);
          }
          modificarProductosState([
            { CambioSeccion: false, Busqueda: palabra !== "", irInicio: true },
            matrizActual,
          ]);
          modificarSpinnerState({
            Desplegar: false,
            Texto: spinnerState["texto"],
          });
          if (errorState["Error"]) {
            modificarErrorState({
              Error: false,
              Mensaje: "Todo bien, todo correcto",
            });
          }
          if (headerState["displayMenu"] === "Index") {
            history.push("/Mercado");
          }
        })
        .catch(function (err) {
          modificarSpinnerState({
            Desplegar: false,
            Texto: spinnerState["texto"],
          });
          modificarProductosState([
            { CambioSeccion: false, Busqueda: false, irInicio: false },
            [],
          ]);
        });
    } else {
      let data = {
        Categorias: [],
        Generos: [],
        Marcas: [],
        Medidas: [],
        Tallas: [],
        Colores: [],
        ParametroOrdenamiento: "",
        ProductosNuevos: true,
        Descuentos: false,
        Todo: false,
        Origen: "Header"

      };
      await axios
        .post(
          apiUrlState + "/Api/Inventario/GetProductos",
          {
            data,
          },
          {}
        )
        .then((response) => {
          console.log("por aqui pase");
          modificarProductosState([
            { CambioSeccion: false, Busqueda: false, irInicio: true },
            response.data.data,
          ]);
          modificarSpinnerState({
            Desplegar: false,
            Texto: spinnerState["texto"],
          });
          if (errorState["Error"]) {
            modificarErrorState({
              Error: false,
              Mensaje: "Todo bien, todo correcto",
            });
          }
        })
        .catch(function (err) {
          modificarSpinnerState({
            Desplegar: false,
            Texto: spinnerState["texto"],
          });
          modificarProductosState([
            { CambioSeccion: false, Busqueda: false, irInicio: false },
            [],
          ]);
          if (!errorState["Error"]) {
            modificarErrorState({
              Error: true,
              Mensaje: "Error al recuperar datos del servidor",
            });
          }
        });
    }
  };

  //Se realiza la peticion para la verificacion de un producto solicitando la cantidad de este en bodega.
  const ConsultarBodega = async (Producto, callback) => {
    let idProducto = Producto["id"];
    const codBodega = Producto.Bodega;
    // idProducto = idProducto.slice(-1) === "U"? idProducto.slice(0, -1) : idProducto
    // let posMarca = Producto.Nombre.indexOf("CROATTA");
    // let Marca = posMarca == -1? "DK" : "CROATTA";

    if (!spinnerState["Desplegar"]) {
      modificarSpinnerState({
        Desplegar: !spinnerState["Desplegar"],
        Texto: "Agregando producto...",
      });
    }
    // Si hay un vendedor logueado, verifica cantidades disponibles en bodega 2 (Preventa)
    let rutaVerificacion = "/Api/Manager/VerificarProducto/" + idProducto
    if (localStorage.getItem("Vendedor")) {
      console.log("Entra aca", Bodega)
      rutaVerificacion = "/Api/Manager/VerificarProducto/" + idProducto + `/${codBodega}`
    }
    await axios
      .get(
        apiUrlState + rutaVerificacion,
        {}
      )
      .then((response) => {
        if (response.data.existe) {
          if (errorState["Error"]) {
            modificarErrorState({
              Error: false,
              Mensaje: "Todo bien, todo correcto",
            });
          }
          callback(response.data.cantidad);
        } else {
          if (!errorState["Error"]) {
            modificarErrorState({
              Error: true,
              Mensaje: "Error al recuperar datos del servidor",
            });
          }
          callback(-100);
        }
        modificarSpinnerState({
          Desplegar: false,
          Texto: spinnerState["texto"],
        });
      })
      .catch(function (err) {
        if (!errorState["Error"]) {
          modificarErrorState({
            Error: true,
            Mensaje: err.response.data.error_message,
          });
          modificarSpinnerState({
            Desplegar: false,
            Texto: spinnerState["texto"],
          });
        }
      });
  };

  //Limpia los states de todos los filtros para no dejar ninguno seleccionado.
  // const limpiarFiltros = () => {
  //   if (categoriasState.Hombre !== null || categoriasState.Mujer !== null) {
  //     modificarCategoriasState({ Hombre: null, Mujer: null });
  //   }
  //   if (tallasState[1].length > 0) {
  //     modificarTallasState([
  //       {
  //         S: false,
  //         M: false,
  //         L: false,
  //         XL: false,
  //         XXL: false,
  //         U: false,
  //         t30: false,
  //         t32: false,
  //         t34: false,
  //         t36: false,
  //         t6: false,
  //         t8: false,
  //         t10: false,
  //         t12: false,
  //       },
  //       [],
  //     ]);
  //   }
  //   if (coloresState[1].length > 0)
  //     modificarColoresState([
  //       {
  //         Azul: false,
  //         Blanco: false,
  //         Negro: false,
  //         Rojo: false,
  //         Violeta: false,
  //         Gris: false,
  //         Naranja: false,
  //         Verde: false,
  //         Rosa: false,
  //         Amarillo: false,
  //       },
  //       [],
  //     ]);
  //   if (marcasState[1].length > 0)
  //     modificarMarcasState([
  //       { Croatta: false, Valtimor: false, Sambarlot: false, Fishbone: false },
  //       [],
  //     ]);
  // };

  //Determina si el texto ingresado contiene un caracter especial.
  const tiene_caracter_especial = (texto) => {
    var letras =
      "abcdefghijklmnñopqrstuvwxyzABCDEFGHIJKLMNÑOPQRSTUVWXYZ0123456789 ";
    texto = texto.toLowerCase();
    for (var i = 0; i < texto.length; i++) {
      if (letras.indexOf(texto.charAt(i), 0) === -1) {
        return true;
      }
    }
    return false;
  };

  //Detecta el evento de escritura y realiza la busqueda por filtro basado en este texto y validandolo.
  const escribiendo = (e) => {
    setConsultaLupa({ value: e.target.value });
    if (tiene_caracter_especial(e.target.value)) {
      var elemento = document.getElementById(e.target.id);
      elemento.value = e.target.value.replace(
        e.target.value[e.target.value.length - 1],
        ""
      );
    } else if (headerState["displayMenu"] === "Index") {
      console.log("Buscar 1")
      actualizarSessionStorage("TipoCliente", "Minoristas");
      //modificarCarritoState({"Accion": "Activo" , "Carrito": []});
      //modificarHeaderState({"srcLogo": recursosUrlState + "/img/Header/logos/logo-negro.webp", "srcBolsa": recursosUrlState + "/img/Header/iconos/bolso-negro.webp", "srcFlecha": recursosUrlState + "/img/Index/web/botones/flecha-derecha-negro.webp", "displayBuscar": "in-line", "displayMenu": "Productos", "color": "negro", "Seccion": "New", "Cliente": "Minoristas", "BotonCatalogo": headerState["BotonCatalogo"]})
      // window.location.href = "/Mercado";
    } else if (headerState["displayMenu"] !== "Index") {
      console.log("Buscar 2")
      var antes = e.target.value;
      if (tiene_caracter_especial(antes)) {
        e.target.value = "";
      }
      /**
             * else{
                setTimeout(function() {
                    if(antes === e.target.value && antes[0] !== " "){
                        //limpiarFiltros();
                        obtenerProductosFiltro(antes);
                    }
                }, 500)
            }
             * 
             */
    }
  };

  //Disminuye la cantidad de un producto en el carrito en 1 segun la talla ingresada de este.
  //Actualiza el state del carrito y tambien el sesion storage.
  const disminuirCantidad = (producto) => {
    if (!presionEnter) {
      for (var i = 0; i < carrito["Carrito"].length; i++) {
        if (producto["id"] === carrito["Carrito"][i]["id"]) {
          if (carrito["Carrito"][i]["Cantidad"] - 1 === 0)
            carrito["Carrito"].splice(i, 1);
          else carrito["Carrito"][i]["Cantidad"] -= 1;
        }
      }
      const carritoNew = [...carrito["Carrito"]];
      modificarCarritoState({ Accion: "Activo", Carrito: carritoNew });
      actualizarSessionStorage("Carrito", JSON.stringify(carritoNew));
    }
    presionEnter = false;
  };

  //Se realiza la validacion en bodega para aumentar en 1 la cantidad en carrito de este, si no existe lo crea.
  //Actualiza el state del carrito y tambien el localStorage.
  const aumentarCantidad = async (producto, e) => {
    if (!presionEnter) {
      if (!spinnerState["Desplegar"]) {
        modificarSpinnerState({
          Desplegar: !spinnerState["Desplegar"],
          Texto: "Disminuyendo producto...",
        });
      }
      await ConsultarBodega(producto, function (cantidadFisica) {
        if (producto["Cantidad"] + 1 <= cantidadFisica) {
          for (var i = 0; i < carrito["Carrito"].length; i++) {
            if (producto["id"] === carrito["Carrito"][i]["id"]) {
              carrito["Carrito"][i]["Cantidad"] += 1;
            }
          }
          const carritoNew = [...carrito["Carrito"]];
          modificarCarritoState({ Accion: "Activo", Carrito: carritoNew });
          actualizarSessionStorage("Carrito", JSON.stringify(carritoNew));
          modificarSpinnerState({ Desplegar: false, Texto: "" });
        } else {
          var elemento = document.getElementById(e.target.id + "Cantidad");
          elemento.classList.add("hint--always");
          elemento.classList.add("hint--bottom");
          elemento.classList.add("hint--bounce");
          setTimeout(function () {
            elemento.classList.remove("hint--always");
            elemento.classList.remove("hint--bottom");
            elemento.classList.remove("hint--bounce");
          }, 1000);
        }
      });
    }
    presionEnter = false;
  };

  //Elimina un producto del state del carrito.
  const borrarElemento = (producto) => {
    if (!spinnerState["Desplegar"]) {
      modificarSpinnerState({
        Desplegar: !spinnerState["Desplegar"],
        Texto: "Agregando producto...",
      });
    }
    for (var i = 0; i < carrito["Carrito"].length; i++) {
      if (producto["id"] === carrito["Carrito"][i]["id"]) {
        carrito["Carrito"].splice(i, 1);
      }
    }
    const carritoNew = [...carrito["Carrito"]];
    modificarCarritoState({ Accion: "Activo", Carrito: carritoNew });
    actualizarSessionStorage("Carrito", JSON.stringify(carritoNew));
    modificarSpinnerState({ Desplegar: false, Texto: "" });
  };

  //Comprueba mediante el evento ingresado si la tecla presionada es 'Enter', y actualiza la variable global.
  const comprobarEnter = (e) => {
    presionEnter = e.key === "Enter";
  };

  //Limpia el carrito de todos los prudctos añadidos, dejando el state vacio.
  const limpiarCarrito = () => {
    const carritoNew = [];
    modificarCarritoState({ Accion: "Activo", Carrito: carritoNew });
    actualizarSessionStorage("Carrito", JSON.stringify(carritoNew));
  };

  //Lleva a la vista de pedido.
  const llevarAPedido = async () => {
    enviarEventoFacebook();
    await axios.post(apiUrlState + "/Api/Inventario/GuardarCarrito", carrito);
    await ActulizarDescuentoV2();
    if (headerState["Cliente"] === "Mayoristas") {
      //ReactPixel.track("Carrito", "AddToCart");
      var cantidadProd = 0;
      for (var i = 0; i < carrito["Carrito"].length; i++) {
        cantidadProd += carrito["Carrito"][i]["Cantidad"];
      }
      if (cantidadProd >= 6) {
        window.location.href = "/Pedido";
      } else
        modificarErrorState({
          Error: true,
          Mensaje: "Recuerda que debes añadir 6 unidades o más",
        });
    } else {
      window.location.href = "/Pedido";
    }
  };

  //Lleva a la vista de Ayuda.
  const llevarAAyuda = () => {
    window.location.href = "/Ayuda";
  };

  //Lleva a la vista de Inicio de Sesion.
  // const llevarAIniciarSesion = () => {
  //   window.location.href = "/InicioSesion";
  // };
  //Lleva a la vista de Inicio de Sesion.
  const llevarAMayorista = () => {
    window.location.href = "/Mayoristas";
  };
  //Lleva a la vista de Inicio de Sesion.
  const llevarAMinorista = () => {
    window.location.href = "/";
  };

  //Se hace una revision en la sessionStorage para recuperar el carrito y llevar a la vista de mercado, si es mayorista y no esta logueado lleva a inicio de sesion.
  const CambioProductos = (mercado) => {
    actualizarSessionStorage("TipoCliente", mercado);
    modificarHeaderState({
      srcLogo: recursosUrlState + "/img/Email/45441614379749154.png",
      srcBolsa: recursosUrlState + "/img/Index/web/home/carrito.png",
      srcFlecha:
        recursosUrlState + "/img/Index/web/botones/flecha-derecha-negro.webp",
      displayBuscar: "in-line",
      displayMenu: "Index",
      color: "negro",
      Seccion: headerState["Seccion"],
      Cliente: mercado,
      BotonCatalogo: headerState["BotonCatalogo"],
    });
    if (mercado === "Minoristas") {
      window.location.href = "/Mercado";
    } else window.location.href = "/Mayoristas";
  };

  //Desplegar menu de filtros en Header
  const desplegarFiltros = () => {
    modificarFiltrosState({
      Genero: filtrosState["Genero"],
      Categoria: filtrosState["Categoria"],
      CambioFiltro: false,
      CambioCategoria: false,
      filtroResumido: true,
      posicionFiltros: "0%",
      width: "300px",
    });
  };

  //Compactar menu de filtros en Header
  const cerrarFiltros = () => {
    modificarFiltrosState({
      Genero: filtrosState["Genero"],
      Categoria: filtrosState["Categoria"],
      CambioFiltro: false,
      CambioCategoria: false,
      filtroResumido: true,
      posicionFiltros: "-100%",
      width: "0px",
    });
  };

  //Cierra la sesion del cliente, limpiando los datos del clienteState y tambien actualizando el localStorage.
  const cerrarSesion = () => {
    let cierreDeSesion = {
      Registro: false,
      Accion: "Activo",
      Sesion: false,
      TipoDocumento: "",
      NumeroDocumento: "",
      Nombres: "",
      Apellidos: "",
      Correo: "",
      Celular: "",
      FechaNacimiento: "",
      Genero: "",
      Pais: "",
      Ciudad: "",
      Departamento: "",
      Direccion: "",
      Barrio: "",
    };
    localStorage.setItem("Cliente", JSON.stringify(cierreDeSesion));
    modificarClienteState(cierreDeSesion);
  };
  //Esta funcion comprueba si existe la imagen en version jpg del producto, en caso que no, se lanza la imagen de respaldo general 'base'.

  const comprobarImgProducto = (codigo, talla) => {
    const imagen = document.getElementById(codigo + talla);
    let img = document.createElement("img");
    img.src = recursosUrlState + "/img/Productos/Ref " + codigo + ".webp";
    imagen.innerHTML =
      "<img class ='img-producto-carrito' src= '" +
      recursosUrlState +
      "/img/Productos/Ref " +
      codigo +
      ".webp' alt = '" +
      codigo +
      "' loading = 'lazy' />";
    img.onerror = function () {
      imagen.innerHTML =
        "<img class ='img-producto-carrito' src= '" +
        recursosUrlState +
        "/img/General/base.png' alt = '" +
        codigo +
        "' loading = 'lazy' />";
    };
  };

  const logOutVendedor = () => {
    localStorage.removeItem("Vendedor");
    modificarVendedorState(null);
    localStorage.removeItem("Bodega");
    setBodega("03");
    localStorage.removeItem("Carrito");
    window.location.href = "/Preventa";
  }

  const setearBodega = (bodega) => {
    console.log("Seteando bodega")
    setBodega(bodega);
    localStorage.setItem("Bodega", bodega);
    window.location.reload();
  }

  //Actualiza los states de cliente, el header y el carrito mediante el sessionStorage en caso de reinicio de pagina.
  useEffect(() => {
    const carritoSession = localStorage.Carrito;
    const clienteSession = localStorage.Cliente;
    const tipoClienteSession = localStorage.TipoCliente;
    const administradorSession = localStorage.Administrador;
    if (
      headerState["Cliente"] === "" &&
      tipoClienteSession !== "undefined" &&
      tipoClienteSession !== ""
    ) {
      var header = JSON.parse(JSON.stringify(headerState));
      header["Cliente"] = tipoClienteSession;
      modificarHeaderState(header);
    }
    if (
      carrito["Accion"] === "Recuperar" &&
      carritoSession !== "undefined" &&
      carritoSession !== undefined &&
      carritoSession !== ""
    ) {
      modificarCarritoState({
        Accion: "Activo",
        Carrito: JSON.parse(carritoSession),
      });
    }
    if (
      administradorState["Recuperar"] &&
      administradorSession !== "undefined" &&
      administradorSession !== undefined &&
      administradorSession !== ""
    ) {
      modificarAdministradorState(JSON.parse(administradorSession));
    }
    if (
      clienteState["Accion"] === "Recuperar" &&
      clienteSession !== undefined &&
      clienteSession !== "undefined" &&
      clienteSession !== ""
    ) {
      modificarClienteState({
        ...JSON.parse(clienteSession),
        Accion: "Activo",
      });
    }
  });

  //Retorna el precio ingresado sin signos de peso ni puntos.
  // const arreglarPrecio = (precio) => {
  //   var separacion = precio;
  //   if (precio.indexOf("$") !== -1) separacion = precio.split(" ")[1];
  //   var separacion2 = separacion.split(".");
  //   return parseInt(separacion2[0] + separacion2[1]);
  // };

  const calcularDescuento = (precio, descuento) => {
    const precioInt = parseInt(precio);
    const descuentoAplicar = parseInt(descuento);
    return precioInt - precioInt * (descuentoAplicar / 100);
  };
  const consultar = (e) => {
    if (consultaLupa.value !== "") {
      obtenerProductosFiltro(consultaLupa.value);
      cerrarFiltros();
    }
  };
  const consultarConEnter = (e) => {
    if (e.key === "Enter" && consultaLupa.value !== "") {
      obtenerProductosFiltro(consultaLupa.value);
      cerrarFiltros();
    }
  };
  //Esta funcion reemplaza el 'webp' por 'png' en un string
  const cambioPng = (url) => {
    return url.replace(/webp/g, "png");
  };

  const opaco =
    headerState["Cliente"] === "Mayoristas" ||
    LandingHeader["Estado"] ||
    ViewDetalle["Estado"]
      ? "rgb(255, 255, 255, 255)"
      : "rgb(0, 0, 0, 0)";

  return (
    <>
      {headerState["Cliente"] === "Mayoristas" ? (
        <div
          id={AdminHeaderState.second}
          style={{
            background: "black",
          }}
        >
          <div
            className={AdminHeaderState.principal}
            style={{
              justifyContent: "flex-end",
              zIndex: "100",
              background: "black",
            }}
          >
            <div id="espacioLogo">
              <p className="textHeader">
                Venta mayorista a partir de 6 prendas
              </p>
            </div>

            <div className="centerHeaderExtra oculto">
              {/* <div>
                <p
                  className="FuenteHelvetica"
                  style={{
                    color: "white",
                    marginTop: "3%",
                    marginBottom: "auto",
                  }}
                >
                  Follow Us:
                </p>
              </div> */}
              <div>
                <a href="https://api.whatsapp.com/send?phone=573195123051&text=Hola Basic Couture quisiera más información para hacer mi compra">
                  <img
                    src="https://hosting.basic.dekada.co/BasicCouture/img/Index/web/home/whatsapp.png"
                    className="RedesSociales"
                  ></img>
                </a>
              </div>
              <div>
                <a href="https://www.facebook.com/basic.couturecol">
                  <img
                    src="https://hosting.basic.dekada.co/BasicCouture/img/Index/web/home/Facebook.png"
                    className="RedesSociales "
                  ></img>
                </a>
              </div>
              <div>
                <a href="https://www.instagram.com/dk.tiendaoficial?igsh=MTF6dmFsNmtidDFwYQ%3D%3D&utm_source=qr">
                  <img
                    src="https://hosting.basic.dekada.co/BasicCouture/img/Index/web/home/instagram.png"
                    className="RedesSociales "
                  ></img>
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div id={AdminHeaderState.second}>
          <div
            className="centerHeader"
            style={{
              justifyContent: "flex-end",
              zIndex: "100",
              background: "black",
            }}
          >
            <div id="espacioLogo">
              <p className="textHeader" >
                {/* BLACK FRIDAY */}
              </p>
            </div>
            <div className="centerHeaderExtra oculto">
              {/* <div>
                <p
                  className="FuenteHelvetica"
                  style={{
                    color: "white",
                    marginTop: "3%",
                    marginBottom: "auto",
                  }}
                >
                  Follow Us:
                </p>
              </div> */}
              <div>
                <a href="https://api.whatsapp.com/send?phone=573195123051&text=Hola Basic Couture quisiera más información para hacer mi compra">
                  <img
                    src="https://hosting.basic.dekada.co/BasicCouture/img/Index/web/home/whatsapp.png"
                    className="RedesSociales"
                  ></img>
                </a>
              </div>
              <div>
                <a href="https://www.facebook.com/basic.couturecol">
                  <img
                    src="https://hosting.basic.dekada.co/BasicCouture/img/Index/web/home/Facebook.png"
                    className="RedesSociales "
                  ></img>
                </a>
              </div>
              <div>
                <a href="https://www.instagram.com/basic.couturecol?igsh=MWN0OHppZnRobXN5eA%3D%3D&utm_source=qr">
                  <img
                    src="https://hosting.basic.dekada.co/BasicCouture/img/Index/web/home/instagram.png"
                    className="RedesSociales "
                  ></img>
                </a>
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        className={AdminHeaderState.principal}
        style={{ zIndex: "100", background: "white", top: "2.5rem" }}
      >
        <div style={{ zIndex: "800", padding: "0.2rem 0.2rem 0.2rem 0.9rem" }}>
          <div id="FiltrosMovil" style={propiedadesFiltro}>
            <div
              id="botonCerrar"
              onClick={() => cerrarFiltros()}
              style={{ cursor: "pointer" }}
            >
              <img
                className="flechaFiltro SinDecoracion"
                src={
                  recursosUrlState +
                  "/img/Index/web/botones/flecha-izquierda-negro.webp"
                }
                alt="FlechaIzq"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src =
                    recursosUrlState +
                    "/img/Index/web/botones/flecha-izquierda-negro.png";
                }}
              />
            </div>
            {/* <div>
              <hr
                style={{
                  color: "#000000",
                  backgroundColor: "#000000",
                  height: 3,
                  borderColor: "#000000",
                  marginBottom: "0px",
                  marginTop: "80px",
                  marginLeft: "20px",
                  marginRight: "20px",
                }}
              />
            </div> */}

            <div>
              <div id="BuscarFiltro">
                <span
                  className="FuentePoppinsL"
                  style={{ fontSize: "20px", color: "black" }}
                >
                  Buscar
                </span>
                <input
                  type="text"
                  id="BarraFiltro"
                  style={{ color: "black" }}
                  onChange={(e) => escribiendo(e)}
                  onKeyPress={(e) => consultarConEnter(e)}
                  autoComplete="off"
                ></input>

                <input
                  className="lupa zoomBuscarMovil"
                  type="image"
                  alt="imglupaBusqueda"
                  src={recursosUrlState + "/img/Header/iconos/lupa.png"}
                  onClick={(e) => consultar(e)}
                ></input>
              </div>

              {
                RutaActual !== "/Mayoristas" && !vendedor ? <div style={{ marginTop: "25%" }} >
                <Filtros
                  filtrosState={filtrosState}
                  modificarFiltrosState={modificarFiltrosState}
                  productosState={productosState}
                  modificarProductosState={modificarProductosState}
                  tallasState={tallasState}
                  modificarTallasState={modificarTallasState}
                  coloresState={coloresState}
                  modificarColoresState={modificarColoresState}
                  marcasState={marcasState}
                  modificarMarcasState={modificarMarcasState}
                  medidasState={medidasState}
                  modificarMedidasState={modificarMedidasState}
                  errorState={errorState}
                  modificarErrorState={modificarErrorState}
                  spinnerState={spinnerState}
                  modificarSpinnerState={modificarSpinnerState}
                  apiUrlState={apiUrlState}
                  modificarApiUrlState={modificarApiUrlState}
                  recursosUrlState={recursosUrlState}
                  modificarRecursosUrlState={modificarRecursosUrlState}
                  categoriasState={categoriasState}
                  modificarCategoriasState={modificarCategoriasState}
                  headerState={headerState}
                  modificarHeaderState={modificarHeaderState}
                  CatalogoState={false}
                  actualizarSessionStorage={actualizarSessionStorage}
                  subCategoriasState={subCategoriasState}
                  modificarSubCategoriasState={modificarSubCategoriasState}
                  refNoCatalogoState={refNoCatalogoState}
                  modificarRefNoCatalogoState={modificarRefNoCatalogoState}
                  vendedor={vendedor}
                  modificarVendedorState={modificarVendedorState}
                  Bodega={Bodega}
                  setBodega={setBodega}
                  BodegaCatalogos={BodegaCatalogos}
                  setBodegaCatalogos={setBodegaCatalogos}
                  checkedProductos={checkedProductos}
                  setCheckedProductos={setCheckedProductos}
                />
              </div> : null
              }
              
            </div>
          </div>
          {
                RutaActual !== "/Mayoristas" ? <img
                src={recursosUrlState + menuActual}
                id="MenuFiltros"
                alt="Menu"
                onClick={() => desplegarFiltros()}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = recursosUrlState + cambioPng(menuActual);
                }}
              ></img> : null
              }
          
        </div>

        <div id="espacioLogo">
          <a href={localStorage.getItem("Vendedor")? "/Preventa/Catalogo" : "/"} >
            <img
              src={srcLogo}
              id="ImagenCroatta"
              alt="LogoCroatta"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = cambioPng(srcLogo);
              }}
            ></img>
          </a>
        </div>

        <div className="centerHeaderExtra">
          {localStorage.getItem("Vendedor") ? (
            <>
              <button 
                className="dropdown-toggle" 
                data-toggle="dropdown"
                style={{background: "transparent", padding: "5px"}}
              >
                {vendedor.Nombre}
              </button>
              <div className="dropdown-menu">
                {/* <a class="dropdown-item" onClick={()=>{setearBodega("3")}} >Bodega: Principal</a> */}
                {/* <a class="dropdown-item" onClick={()=>{setearBodega("6")}} >Bodega: Preventa</a> */}
                {/* <a class="dropdown-item" onClick={()=>{setearBodega("2")}} >Bodega: Cali</a> */}
                {/* <a class="dropdown-item" onClick={()=>{setearBodega("4")}} >Bodega: Bogotá</a> */}
                <a className="dropdown-item" onClick={logOutVendedor} >Salir</a>
              </div>
            </>
          ) : null}

          <a style={{ padding: "0.2rem" }}>
            <h2
              id="IconHeaderMayo"
              className={`btn ${headerState["Cliente"]==="Minoristas"? "btn-active" : ""}` }
              onClick={() => llevarAMinorista()}
            >
              MINORISTAS
            </h2>
          </a>
          <a style={{ padding: "0.2rem" }}>
            <h2
              id="IconHeaderMayo"
              className={`btn ${headerState["Cliente"]==="Mayoristas"? "btn-active" : ""}` }
              onClick={() => llevarAMayorista()}
            >
              MAYORISTAS
            </h2>
          </a>

          <div onClick={() => llevarAAyuda()} style={{ padding: "0.2rem" }}>
            <img
              className="botonHeader "
              src={recursosUrlState + "/img/Header/botones/ayuda-negro.webp"}
              alt="ayuda"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src =
                  recursosUrlState + "/img/Header/botones/ayuda-negro.png";
              }}
            ></img>
          </div>

          <div style={{ padding: "0.2rem" }}>
            <nav
              className="navbar navbar-default"
              style={{
                boxShadow:
                  "0 2px 5px 0 rgba(0, 0, 0, 0), 0 2px 10px 0 rgba(0, 0, 0, 0)",
                padding: "0rem",
                backgroundColor: "withe",
              }}
            >
              <div className="container-fluid" style={{ padding: "0rem", display: `${window.location.pathname == "/Pedido"? "none" : ""}` }}>
                <div className="navbar-header">
                  <div
                    style={{ cursor: "pointer", backgroundColor: "white" }}
                    className="navbar-toggle collapsed"
                    data-toggle="collapse"
                    data-target="#bs-example-navbar-collapse-1"
                    aria-expanded="false"
                  >
                    <div className="contenedorCarrito">
                      <img
                        src={srcBolsa}
                        className="botonHeader"
                        alt="Bolsa"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = cambioPng(srcBolsa);
                        }}
                      ></img>
                      <p
                        className="centrado CantidadCarrito"
                        style={{ color: colorActual }}
                      >
                        {cantidadTotal}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="collapse navbar-collapse cajaCarrito"
                  id="bs-example-navbar-collapse-1"
                >
                  {cantidadTotal > 0 ? (
                    <ul className="nav navbar-nav">
                      <div id="productosCarro">
                        {carrito["Carrito"].map((producto) => (
                          <li
                            className="nav-item cartaCarrito"
                            key={producto["Codigo"] + producto["Talla"]}
                          >
                            <div className="row">
                              <div
                                className="col-6"
                                id={producto["Codigo"] + producto["Talla"]}
                              >
                                <img
                                  className="img-producto-carrito"
                                  src={
                                    recursosUrlState +
                                    "/img/Productos/Ref " +
                                    producto["Codigo"] +
                                    ".webp"
                                  }
                                  alt={producto["Codigo"]}
                                  loading="lazy"
                                  onError={(e) =>
                                    comprobarImgProducto(
                                      producto["Codigo"],
                                      producto["Talla"]
                                    )
                                  }
                                />
                              </div>
                              <div className="col-6 textoCarrito text-left">
                                <p style={{ marginBottom: 0 }}>
                                  Categoria: {producto["Categoria"]}
                                </p>
                                <p style={{ marginBottom: 0 }}>
                                  Codigo: {producto["Codigo"]}
                                </p>
                                <p style={{ marginBottom: 0 }}>
                                  Talla: {producto["Talla"]}
                                </p>
                                <p>
                                  Precio Und:{" "}
                                  {formatNumber(
                                    headerState["Cliente"] === "Mayoristas"
                                      ? producto.DescuentoMayorista !== 0
                                        ? calcularDescuento(
                                            producto["PrecioMayorista"],
                                            producto["DescuentoMayorista"]
                                          )
                                        : producto["PrecioMayorista"]
                                      : producto.DescuentoMinorista !== 0
                                      ? calcularDescuento(
                                          producto["PrecioMinorista"],
                                          producto["DescuentoMinorista"]
                                        )
                                      : producto["PrecioMinorista"]
                                  )}
                                </p>
                                <div
                                  className="row SinDecoracionHeader"
                                  id="LineaCarrito"
                                  style={{ justifyContent: "flex-start" }}
                                >
                                  <button
                                    className="SinDecoracionHeader"
                                    onKeyPress={(e) => {
                                      return comprobarEnter(e);
                                    }}
                                    tabIndex="-1"
                                    style={{
                                      fontSize: 25,
                                      marginLeft: 15,
                                      marginRight: 10,
                                      marginBottom: 10,
                                    }}
                                    onClick={() => disminuirCantidad(producto)}
                                  >
                                    -
                                  </button>
                                  <p
                                    style={{
                                      marginTop: 5,
                                      marginLeft: 10,
                                      marginRight: 10,
                                    }}
                                    id={
                                      producto["Codigo"] +
                                      producto["Talla"] +
                                      "Cantidad"
                                    }
                                    className="FuenteBebas"
                                    data-hint="Cantidad Maxima"
                                  >
                                    {producto["Cantidad"]}
                                  </p>
                                  <button
                                    className="SinDecoracionHeader"
                                    onKeyPress={(e) => {
                                      return comprobarEnter(e);
                                    }}
                                    tabIndex="-1"
                                    style={{
                                      fontSize: 25,
                                      marginLeft: 10,
                                      marginRight: 10,
                                      marginBottom: 10,
                                    }}
                                    id={producto["Codigo"] + producto["Talla"]}
                                    onClick={(e) =>
                                      aumentarCantidad(producto, e)
                                    }
                                  >
                                    +
                                  </button>
                                  <img
                                    className="SinDecoracionHeader botonBorrar"
                                    src={
                                      recursosUrlState +
                                      "/img/Header/botones/papelera-negro.webp"
                                    }
                                    alt="papelera"
                                    loading="lazy"
                                    onClick={() => borrarElemento(producto)}
                                    onError={(e) => {
                                      e.target.onerror = null;
                                      e.target.src =
                                        recursosUrlState +
                                        "/img/Header/botones/papelera-negro.png";
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                      </div>
                      <li
                        className="nav-item"
                        style={{
                          position: "relative",
                          bottom: "-230px",
                          height: "40px",
                        }}
                      >
                        <div className=" espacioPago">
                          <div>
                            <p id="TotalCarrito">
                              Total: $ {formatNumber(calcularTotalCarrito())}
                            </p>
                          </div>

                          <div className="">
                            <p
                              className=""
                              style={{
                                fontFamily: "Bebas",
                                fontSize: "22px",
                                cursor: "pointer",
                              }}
                              onClick={() => limpiarCarrito()}
                            >
                              LIMPIAR
                            </p>
                          </div>
                          <div>
                            <p
                              className=""
                              style={{
                                fontFamily: "Bebas",
                                fontSize: "22px",
                                cursor: "pointer",
                              }}
                              onClick={() => llevarAPedido()}
                            >
                              PAGAR
                            </p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  ) : (
                    <ul className="nav navbar-nav">
                      <p id="nadaEnElCarrito">
                        ¿Aún no añades nada al carrito?
                      </p>
                    </ul>
                  )}
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
}
export default Header;
